import { render, staticRenderFns } from "./0-liuxue.vue?vue&type=template&id=5b9556ba&scoped=true"
import script from "./0-liuxue.vue?vue&type=script&lang=js"
export * from "./0-liuxue.vue?vue&type=script&lang=js"
import style0 from "./0-liuxue.vue?vue&type=style&index=0&id=5b9556ba&prod&scoped=scoped&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5b9556ba",
  null
  
)

export default component.exports